/* Reset global para garantir que não haja margens ou paddings indesejados */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.vitrine-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  padding-top: 80px; /* Espaço para o header fixo */
}

.vitrine-content {
  flex: 1;
  padding: 20px 15px 2rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.vehicle-grid-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.vehicle-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Ajustes para mobile */
@media (max-width: 768px) {
  .vitrine-page {
    padding-top: 60px;
  }

  .vitrine-content {
    padding: 10px 10px 2rem;
    width: 100%;
    margin: 0;
  }

  .vehicle-grid-container {
    width: 100%;
    margin-top: 10px;
    padding: 0;
  }

  .vehicle-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

/* Ajustes para tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .vehicle-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Ajustes para desktop */
@media (min-width: 1025px) {
  .vehicle-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Garantir que os cards não causem overflow */
.vehicle-card {
  width: 100%;
  max-width: 100%;
  margin: 0;
  box-sizing: border-box;
}

.no-results {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-top: 2rem;
}

.category-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.category-buttons button {
  background-color: #E3E9EE;
  color: #333;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.category-buttons button.active {
  background-color: #ff6600;
  color: white;
}

.category-buttons button:hover {
  background-color: #004080;
  color: white;
}

.image-carousel-container {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.search-bar-container {
  width: 100%;
  max-width: 1200px;
  margin: 25px auto 20px;
  position: relative;
  z-index: 2;
  padding: 0 15px;
}

.warning-message {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

/* Seção de anúncios */
.ads-section {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  z-index: 5;
}

.main-ad {
  width: 100%;
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.secondary-ads {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.secondary-ads .ad-container {
  flex: 1;
  min-height: 250px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

/* Ajustes responsivos para anúncios */
@media (max-width: 768px) {
  .ads-section {
    margin: 10px auto;
    padding: 0 10px;
  }

  .secondary-ads {
    flex-direction: column;
    align-items: center;
  }

  .secondary-ads .ad-container {
    width: 100%;
    max-width: 100%;
  }

  .main-ad {
    min-height: 60px;
  }
}

/* Estilos para o modal de carrossel de imagens */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.vitrine-page {
  animation: fadeIn 0.3s ease-in;
}

/* Estilos para loading */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Melhorias de acessibilidade */
.category-buttons button:focus {
  outline: 2px solid #004080;
  outline-offset: 2px;
}

/* Estilos para mensagens de feedback */
.feedback-message {
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
}

.feedback-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.feedback-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Ajuste responsivo */
@media (max-width: 768px) {
  .search-bar-container {
    margin: 15px auto 15px;
    padding: 0 10px;
  }
}