/* HeroSection.css */

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  width: 100%;
  padding-top: 150px;
  
}

.hero-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 2rem;
  margin-bottom: 4rem;
  animation: fadeInDown 1s ease-out forwards;
}

.hero-image-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin-right: 2rem;
}

.hero-image-background {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100%;
  height: 100%;
  background-color: #003366;
  z-index: 1;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./img/fachada.webp');
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.hero-content {
  flex: 1;
  text-align: center;
  max-width: 600px;
}

.hero-content h1 {
  font-size: 2.2rem;
  color: #003366;
  margin-bottom: 0.75rem;
}

.hero-content p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.cta-button {
  background-color: #003366;
  color: white;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .hero-wrapper {
    padding: 0 2rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    padding-top: 100px;
    padding-left: 0;
    padding-right: 0;
    width: 100vw; /* Adicionado para garantir largura total da viewport */
    overflow-x: hidden; /* Adicionado para evitar scroll horizontal */
  }

  .hero-wrapper {
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    margin: 0;
    box-sizing: border-box; /* Adicionado para incluir padding na largura total */
  }

  .hero-image-container {
    margin-right: 0;
    margin-bottom: 1.5rem;
    width: 250px;
    height: 250px;
  }

  .hero-content {
    text-align: center;
    max-width: 100%;
    padding: 0;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.features-section {
  background-color: #f9f9f9;
  padding: 4rem 2rem;
  text-align: center;
  width: 100%;
}

.features-section h2 {
  color: #003366;
  margin-bottom: 2rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-item {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 3rem;
  color: #003366;
  margin-bottom: 1rem;
}

.feature-item h3 {
  color: #003366;
  margin-bottom: 0.5rem;
}

.how-it-works-section {
  position: relative;
  padding: 4rem 2rem;
  text-align: center;
  background-color: #003366;
  color: white;
  width: 100%;
}

.how-it-works-section h2 {
  margin-bottom: 2rem;
}

.steps-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.step {
  flex-basis: 200px;
  margin: 1rem;
  text-align: center;
}

.step img {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}

.timeline {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background-color: white;
  transform: translateY(-50%);
  z-index: -1;
}

.timeline::before,
.timeline::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.timeline::before {
  left: 0;
}

.timeline::after {
  right: 0;
}

.cta-section {
  background-color: #f0f0f0;
  padding: 4rem 2rem;
  text-align: center;
  width: 100%;
}

.cta-section h2 {
  color: #003366;
  margin-bottom: 1rem;
}

.cta-button.large {
  font-size: 1.2rem;
  padding: 1rem 2rem;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }

  .steps-container {
    flex-direction: column;
  }

  .step {
    margin-bottom: 2rem;
  }
}