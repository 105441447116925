.search-bar {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  width: 100%;
}

.search-bar input {
  width: 60%;
  max-width: 600px;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
  flex-grow: 1;
}


.search-bar button {
  padding: 0.5rem 1rem;
  background-color: #003366;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar button:hover {
  background-color: #004080;
}

@media (max-width: 768px) {
  .search-bar {
    width: 100%;
    margin: 1rem auto;
  }

  .search-bar input {
    width: calc(100% - 40px);
    border-radius: 2px 0 0 2px;
  }

  .search-bar button {
    width: 40px;
    border-radius: 0 2px 2px 0;
  }
}
