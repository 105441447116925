/* Header.css */

.header {
    background-color: #fff;
    width: 100%;
    border-bottom: 2px solid #e0e0e0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    width: 100px;
    height: auto;
}

.desktop-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-right: 30px;
}

.desktop-menu li {
    margin-left: 30px;
}

.desktop-menu a {
    text-decoration: none;
    color: #1a237e;
    font-weight: 500;
}

.menu-toggle {
    display: none;
}

@media (max-width: 768px) {
    .header {
        width: 100vw;
        overflow-x: hidden;
    }

    .header-content {
        padding: 10px 15px;
        box-sizing: border-box;
    }

    .logo-container {
        margin-right: 0;
    }

    .logo {
        width: 80px;
        height: auto;
    }

    .desktop-menu {
        display: none;
    }

    .menu-toggle {
        display: block;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        padding: 0;
        margin-left: auto;
        margin-right: 15px;
    }

    .mobile-menu-drawer .ant-drawer-body {
        padding: 0;
    }

    .mobile-menu-drawer .ant-menu-item {
        font-size: 18px;
        height: 50px;
        line-height: 50px;
    }

    .mobile-menu-drawer .ant-menu-item a {
        color: rgba(0, 0, 0, 0.85);
    }

    .mobile-menu-drawer .ant-menu-item-icon {
        font-size: 20px;
    }
}

