html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  background-color: #003366;
  color: white;
  width: 100%;
  font-family: Arial, sans-serif;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.footer-logo img {
  width: 200px;
  height: auto;
}

.footer-info {
  text-align: left; /* Alinha o texto à esquerda */
}

.footer-info h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.footer-info p {
  margin: 0.25rem 0;
}

.footer-info a {
  color: white;
  text-decoration: none;
}

.footer-bottom {
  text-align: center;
  padding: 1rem 0;
  background-color: #002855;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
  
  }

  .footer-logo {
    margin-bottom: 1rem;
  }

  .footer-info {
    margin-top: 1rem;
    
  }

  .footer-logo img {
    width: 150px;
    
  }

  .footer-bottom {
    height: 50px;
  }
}


