.vehicle-card {
  width: 100%;
  margin-bottom: 20px;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.vehicle-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.vehicle-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.no-image {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #999;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  min-height: 40px;
}

.card-header h3 {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
  height: 40px;
  display: flex;
  align-items: center;
}

.price .anticon {
  margin-right: 8px;
}

.details p, .seller-info p {
  margin: 5px 0;
}

.details .anticon, .seller-info .anticon {
  margin-right: 8px;
  color: #666;
}

.description {
  color: #666;
  margin: 10px 0;
  min-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1.4;
}

.details, .seller-info {
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}

.vehicle-card-vendedor {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.vendedor-foto {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
  border: 1px solid #eee;
}

.seller-info p {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.seller-info .anticon {
  margin-right: 8px;
  color: #666;
}

.vendor-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.vendor-photo, .dealer-photo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.vendor-photo-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1890ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.vendor-section {
  margin-top: auto;
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
}

.vendor-info, .dealer-info {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

.vendor-header, .dealer-header {
  font-size: 11px;
  color: #8c8c8c;
  margin-bottom: 2px;
}

.vendor-details, .dealer-details {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dealer-details img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.dealer-info {
  border-top: 1px dashed #f0f0f0;
  padding-top: 4px;
  margin-top: 4px;
}

.vendor-details span, .dealer-details span {
  font-size: 12px;
  color: #262626;
}

.vehicle-card .ant-card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.whatsapp-button {
  margin-top: 8px;
  background-color: #25D366 !important;
  border-color: #25D366 !important;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
}

.whatsapp-button:hover {
  background-color: #128C7E !important;
  border-color: #128C7E !important;
  opacity: 0.9;
}

.details {
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.details p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 1.4;
}

.details-button {
  margin-top: 16px;
  background-color: #003366 !important;
  border-color: #003366 !important;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
}

.details-button:hover {
  background-color: #002244 !important;
  border-color: #002244 !important;
  opacity: 0.9;
}

