/* src/components/Shared/Botao.css */

.botao-personalizado {
    background-color: #636F7E; /* Cor de fundo azul */
    color: #fff; /* Cor do texto branca */
    border: none; /* Sem borda */
    padding: 0.5rem 1rem; /* Espaçamento interno */
    border-radius: 5px; /* Cantos arredondados */
    cursor: pointer; /* Cursor de ponteiro */
    transition: all 0.2s ease-in-out; /* Transição suave */
  }
  
  .botao-personalizado:hover {
    background-color: #636F7E; /* Cor de fundo azul mais escuro ao passar o mouse */
  }