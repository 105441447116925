.address-button {
  position: fixed;
  bottom: 0;
  right: 25px; /* Ajustado para 25px */
  background-color: #FF6600; /* Cor laranja */
  color: white;
  border: none;
  padding: 12px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1000;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.1s;
}

.address-button:hover {
  background-color: #FF8533; /* Cor laranja mais clara para hover */
}

.address-button:active {
  transform: translateY(2px); /* Efeito de clique */
  background-color: #E65C00; /* Cor laranja mais escura para clique */
}

.address-button i {
  margin-right: 8px;
}

.gps-icon {
  margin-right: 8px;
  font-size: 18px;
}

.modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 80%;
  max-width: 800px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .address-button {
    right: 25px; /* Mantém 25px em telas mobile */
    padding: 10px 15px;
    font-size: 12px;
  }

  .modal-content {
    width: 90%;
    padding: 15px;
  }
}
