.image-carousel {
  width: 100%;
  max-width: none;
  margin: 0;
  padding: 0;
}

.image-carousel .slick-slide {
  height: 500px;
}

.image-carousel .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.image-carousel .slick-dots {
  bottom: 20px;
}

.image-carousel .slick-dots li button {
  background-color: #ccc;
}

.image-carousel .slick-dots li.slick-active button {
  background-color: #003366;
}

@media (max-width: 768px) {
  .image-carousel .slick-slide {
    height: 300px;
    position: relative;
    overflow: hidden;
  }

  .image-carousel .slick-slide img {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
    max-width: none;
    object-fit: cover;
  }
}