.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 0;
  box-sizing: border-box;
}

.modal-content {
  background-color: white;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: auto;
  position: relative;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  z-index: 2;
}

.carousel-container {
  position: relative;
  background: #f0f2f5;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.carousel-arrow:hover {
  background: rgba(0, 0, 0, 0.7);
}

.carousel-arrow.prev {
  left: 10px;
}

.carousel-arrow.next {
  right: 10px;
}

.carousel-item {
  height: 400px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #f0f2f5;
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.3s;
}

.carousel-item img:hover {
  transform: scale(1.02);
}

.thumbnails-container {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: thin;
  scrollbar-color: #FF6600 #f0f2f5;
}

.thumbnail-item {
  flex: 0 0 80px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.thumbnail-item.active {
  border-color: #FF6600;
  opacity: 1;
  transform: scale(1.05);
}

.thumbnail-item:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.thumbnail-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Estilo para o modal de visualização ampliada */
.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.image-preview-content {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
}

.image-preview-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-preview {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.vehicle-info {
  .header-section {
    margin-bottom: 24px;

    .ant-tag {
      margin-bottom: 8px;
    }

    h2 {
      margin: 8px 0;
    }

    .price {
      color: #FF6600;
      margin: 16px 0;
    }
  }
}

.details-section {
  .ant-descriptions {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }

  .ant-descriptions-item-label {
    background: #f7f7f7;
    width: 120px;
  }
}

.description-section {
  margin: 24px 0;
  text-align: center;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.description-section h4 {
  color: #003366;
  margin-bottom: 16px;
}

.description-section .ant-typography {
  text-align: justify;
  line-height: 1.6;
  color: #333;
}

.contact-section {
  .vendor-info, .dealer-info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;
    background: #f7f7f7;
    border-radius: 8px;
  }

  .vendor-details, .dealer-details {
    margin-left: 16px;
  }

  .whatsapp-button {
    background-color: #25D366;
    border-color: #25D366;
    height: 48px;
    font-size: 16px;
    margin-top: 16px;

    &:hover {
      background-color: #128C7E;
      border-color: #128C7E;
    }
  }
}

/* Estilos para a galeria de miniaturas */
.thumbnails-container {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: thin;
  scrollbar-color: #FF6600 #f0f2f5;
}

.thumbnail-item {
  flex: 0 0 80px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.thumbnail-item.active {
  border-color: #FF6600;
  opacity: 1;
  transform: scale(1.05);
}

.thumbnail-item:hover {
  opacity: 1;
  transform: translateY(-2px);
}

.thumbnail-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Estilização da barra de rolagem */
.thumbnails-container::-webkit-scrollbar {
  height: 6px;
}

.thumbnails-container::-webkit-scrollbar-track {
  background: #f0f2f5;
  border-radius: 3px;
}

.thumbnails-container::-webkit-scrollbar-thumb {
  background: #FF6600;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .modal-overlay {
    padding: 5px;
  }

  .modal-content {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 12px;
    border-radius: 4px;
  }

  .vehicle-detail-modal {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Melhor scroll em iOS */
  }

  .carousel-container {
    margin: -12px -12px 12px -12px; /* Remove espaçamento nas bordas */
  }

  .carousel-item {
    height: 300px;
  }

  /* Ajusta o layout para uma coluna em mobile */
  .vehicle-detail-modal .ant-row {
    margin: 0 !important;
  }

  .vehicle-detail-modal .ant-col {
    padding: 8px !important;
  }

  /* Remove margens extras */
  .details-section, 
  .description-section, 
  .contact-section {
    margin: 12px 0;
    padding: 12px;
  }

  .close-button {
    top: 8px;
    right: 8px;
    font-size: 20px;
  }

  .header-section h2 {
    font-size: 1.5rem;
  }

  .price {
    font-size: 1.3rem;
  }

  .carousel-arrow {
    width: 30px;
    height: 30px;
  }

  .thumbnail-item {
    flex: 0 0 60px;
    height: 45px;
  }
}
